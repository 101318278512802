import type { GetProductResponse, Psdata } from '@vue-storefront/prestashop-api';

export const useCartItem = (product: ComputedRef<Psdata | null>) => {
  const { data: cart } = useCart();

  const quantityInCart = computed(() => {
    const item = cart.value?.products?.find((item) => {
      return item.id_product == product.value?.id_product && item.id_product_attribute == product.value?.id_product_attribute;
    });
    return item?.quantity ?? 0;
  });

  const quantityAvailable = computed(() => {
    return Math.max(0, (product.value?.quantity ?? 0) - quantityInCart.value);
  });

  const quantitySelectable = computed(() => {
    return (product.value?.allow_out_of_stock || product.value?.allow_oosp) == '0' ? Math.max(0, product.value?.quantity - quantityInCart.value) : 99;
  });

  const isAvailable = computed(() => {
    return quantitySelectable.value > 0;
  });

  return {
    quantityInCart,
    quantitySelectable,
    quantityAvailable,
    isAvailable,
  };
};
